<script setup lang="ts">
import { useActiveUser } from '~/utilities/data/activeUser';
import { DialogWrapper } from 'vue3-promise-dialog';

const navigationDrawerRail = ref(false);
const globalActionDrawer = ref(false);
const notificationDrawer = ref(false);
const historyDrawer = ref(false);
const settingsDrawer = ref(false);

const activeUserStore = useActiveUser();
await activeUserStore.load();

const publicConfig = useRuntimeConfig().public;
const environmentName = publicConfig.environmentName;
const environmentColor = publicConfig.environmentColor;
const faviconName = publicConfig.faviconName;

useFavicon(`/${faviconName}.png`, { rel: 'icon' });

</script>


<template>
  <DialogWrapper />

  <LayoutNotification></LayoutNotification>
  <VApp :theme="activeUserStore.getActiveTheme()">
    <VNavigationDrawerLeft v-model:rail="navigationDrawerRail">
      <Navigation />
    </VNavigationDrawerLeft>
    <VNavigationDrawerRight v-model="settingsDrawer">
      <Settings @update:close="settingsDrawer = false" />
    </VNavigationDrawerRight>
    <VAppBar>
      <template v-slot:prepend>
        <VAppBarNavIcon class="ml-1" @click="navigationDrawerRail = !navigationDrawerRail"></VAppBarNavIcon>
        <VChip v-if="environmentName" :color="environmentColor" label prepend-icon="mdi-account">{{ environmentName }}
        </VChip>
        <Breadcrumb />
      </template>
      <template v-slot:append>
        <SearchBtn></SearchBtn>
        <VBtn @click="globalActionDrawer = !globalActionDrawer; notificationDrawer = false; historyDrawer = false"
          icon="mdi-text-box-plus-outline" :color="globalActionDrawer ? 'primary' : ''"
          :variant="globalActionDrawer ? 'tonal' : 'text'"></VBtn>
        <VBtn @click="historyDrawer = !historyDrawer; notificationDrawer = false; globalActionDrawer = false;"
          icon="mdi-history" :color="historyDrawer ? 'primary' : ''" :variant="historyDrawer ? 'tonal' : 'text'"></VBtn>
        <NotificationBtn
          @click="notificationDrawer = !notificationDrawer; historyDrawer = false; globalActionDrawer = false;"
          :color="notificationDrawer ? 'primary' : ''" :variant="notificationDrawer ? 'tonal' : 'text'">
        </NotificationBtn>
        <VDivider />
        <UserMenu @toggle-setting="settingsDrawer = true"></UserMenu>
        <SocketOpenItem></SocketOpenItem>

      </template>
    </VAppBar>
    <VMain>

      <VContainer fluid class="mb-0">
        <slot></slot>
      </VContainer>
    </VMain>
    <VNavigationDrawerRight v-model="globalActionDrawer">
      <VDivider />
      <ListHeader :title="$t('create_options_title')" :text="$t('create_options_description')" />
      <ActionGlobal />
    </VNavigationDrawerRight>
    <VNavigationDrawerRight v-model="notificationDrawer">
      <VDivider />
      <ListHeader :title="$t('notification_title')" :text="$t('notification_description')" />
      <NotificationPanel :active="notificationDrawer" />
    </VNavigationDrawerRight>
    <VNavigationDrawerRight v-model="historyDrawer">
      <VDivider />
      <ListHeader :title="$t('history_title')" :text="$t('history_description')" />
      <UserCourse :active="historyDrawer"></UserCourse>
    </VNavigationDrawerRight>
  </VApp>
</template>

<style lang="scss">
@import "../assets/styles/options";
</style>